import './style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tilesets from './components/Tilesets';
import Moregames from './components/games/Moregames';
import Minigames from './components/games/Minigames';
import Reddit from './components/Reddit';

import tile1 from './static/tile1.png'
import tile2 from './static/tile2.png'
import tile3 from './static/tile3.png'
import logo from './icon.png'

import general from './static/json/general.json';


import { useNavigate } from "react-router-dom";

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');

function getNewestTileset() {

	var playedPortugal = localStorage.getItem("tiles-portugal-best");
	if(playedPortugal == null) {
		return "portugal";
	}
	var tilesets = general["waiting"], ts;
	const todayDate = new Date();
	var dateDate = new Date();

	for(var i = 0; i < tilesets.length; i++) {
		ts = tilesets[i];
		dateDate = new Date(ts["date"]);
		if(dateDate <= todayDate) {
           	return ts["name"];
        }
	}
}

function getUrl(lang) {
		var tileset = getNewestTileset();
		return ("/" + lang + "/tiles/" + tileset);
}


function goToGame({game}) {
	ReactGA.event({
	    category: 'Game',
	    action: 'ChangeTilesets',
	    label: game
	});
	window.location.href = "/en/" + game + "/tilesets";
}

function Icon({game}) {

	var addClass = "";
	var gameName = game[0].toUpperCase() + game.substring(1);

	return (
		<div className={"gameIconBox" + addClass} >

			<img onClick={() => goToGame({game})} className={"gameIcon" + addClass} alt={game} variant="top" src={"/images/games/" + game + "/default.png"}  />
			<span>{gameName}</span>

		</div>
	);
}



function MainPage({lang}) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	function play() {
		ReactGA.event({
	        category: 'Game',
	        action: 'StartGameMainPage'
	    });
	}



    return (
        <div className="mainPart">
        <Container>
            <Row>
            <><h1>{t('title')}</h1>
    	    <p className="intro">{t('shortInstruction')}</p>
    	    <div><Button variant="success" className="default-btn" href={getUrl(lang)} onClick={() => play()} >{t('play')}</Button>
    	    </div></>

    	    <br/>
    	    <a href={"/" + lang + "/tiles/tilesets"}>{t('chooseTileset')}</a>
            </Row>

        </Container>
        </div>
      );
}

function MoreInfo() {
	const { t } = useTranslation();
	const allgames =  [ "pixit", "sparkit", "shuzzle", "segments",  "swapoku", "neighbours", "pairs",  "same", "timed", "infinite", "lines", "unpaired", "tiles"];

	return (
		<>

			<Container className="moreIconTiles">
        	            <Row>
        	                <img alt="Tile1" src={tile1} />
        	                <img alt="Tile2" src={tile2} />
        	                <img alt="Tile3" src={tile3} />
        	            </Row>
        	        </Container>
			<Container className="moreInfo" >

				{/* <h1>All games</h1>
                					<Row>
                					{ allgames.map(g => ( <Icon game={g}  /> )) }
                					</Row> */ }

				<Row>
					<Col sm={9}>
						<h3>{t("tilesHeader1")}</h3>
						<p>{t("tilesText1")}</p>
					</Col>
					<Col sm={3}><img alt="example tile in tiles game" src={tile1} /></Col>
				</Row>
				<Row>
					<Col sm={3}><img alt="tiles game unlimited" src={tile2} /></Col>
					<Col sm={9}>
						<h3>{t("tilesHeader2")}</h3>
						<p>{t("tilesText2")}</p>
					</Col>
				</Row>
				<Row>
					<Col sm={9}>
						<h3>{t("tilesHeader3")}</h3>
						<p>{t("tilesText3")}</p>
					</Col>
					<Col sm={3}><img alt="tiles game" src={tile3} /></Col>
				</Row>
			</Container>

			<div className="mainPart">
				<Container className="instructionInfo">
					<Row>
						<Col sm={12}>
							<h3>{t("howToPlay")}</h3>
							<ol>
								<li>{t("instruction1")}</li>
								<img alt="Tiles Unlimited" src="/images/instruction/instruction1.png"/>
								<br/><span>{t("instructionExample")} </span>
								<li>{t("instruction2")}</li>
								<img alt="Tile Game" src="/images/instruction/instruction2.png"/>
								<li>{t("instruction3")}</li>
								<img alt="Free tile game" src="/images/instruction/instruction3.png"/>
								<li>{t("instruction4")}</li>
								<li>{t("instruction5")}</li>
							</ol>
						</Col>
						<div><Button variant="success" className="default-btn" href={getUrl("en")}>{t('play')}</Button>
			            </div><br/>


					</Row>
				</Container>
			</div>

			<Container className="moreInfo" >
				<Row>
					<Col sm={12}>
						<h3>{t("tilesHeader4")}</h3>
						<p>{t("tilesText4")}</p>
					</Col>

				</Row>
				<Row>
					<Col sm={12}>
						<h3>{t("tilesHeader5")}</h3>
						<p>{t("tilesText5")}</p>
					</Col>

				</Row>
				<Row>
					<Col sm={12}>
						<h3>{t("tilesHeader6")}</h3>
						<p>{t("tilesText6")}</p>
					</Col>

				</Row>
			</Container>


		</>
	);
}

function MoreGamesDiv() {


	return (

		<div className="mainPart">

			<Minigames/>
		</div>
	);
}


function Main({lang}) {

	const { t } = useTranslation();
	const langBasic = (lang == "en") ? "" : lang;
	const description = t("description");
	const title = "Unlimited Tiles - " + t("titleShort");

	return (
		<>
			<Helmet>
                <title>{title}</title>
                <link rel="canonical" href={"https://www.unlimited-tiles.com/" + langBasic} />
                <meta property="og:url" content={"https://www.unlimited-tiles.com/" + langBasic}  data-react-helmet="true" />

                <meta name="description" content={description}/>

                <meta property="og:image" content="https://www.unlimited-tiles.com/banner.png" />
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={description}/>

            </Helmet>
			<MainPage lang={lang}/>
			<br/>
			<MoreInfo/>

			<MoreGamesDiv/>
			<Reddit/>
		</>

	);
};

export default Main;
