import logo from './../../logo.svg';
import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import Instruction from './../Instruction';

import 'animate.css';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');



function Square({value, onSquareClick, tileset}) {
	var tiles = "";

	function getImage() {
		if(value > 0) {
			return <div className="image"><img src={"/images/tilesets/same/d" + value + ".png"} alt="Tile"/></div>;
		} else if(value < 0) {
			return <div className="image animate__animated animate__backOutLeft"><img src={"/images/tilesets/same/d" + (-1)*value + ".png"} alt="Tile"/></div>;
		} else {
			return "";
		}
	}

	function getBackground() {
		return <div className="image"><img src={"/images/tilesets/background.png"} alt="X"/></div>;
	}

    return (
        <td className="smallSquare" onClick={onSquareClick}>
            { getImage() }

        </td>
    );
}

function getRandomStart(tileset) {

	const board = [];
	const R = 20, C = 15;
	const colors = {
		"two": 2,
	    "three": 3,
	    "four": 4,
	    "five": 5,
	    "six": 6,
	    "seven": 7,
	    "eight": 8,
	}

    for (let i = 0; i < C; i++) {
        board[i] = [];
        for (let j = 0; j < R; j++) {
            var ran = 1 + Math.floor(Math.random()*colors[tileset]);
            if(colors[tileset] > 2 && i > 0 && j > 0) {
                var choose = Math.floor(Math.random()*3);
                if(choose == 0) { ran = board[i-1][j];}
                if(choose == 1) { ran = board[i][j-1];}
                if(colors[tileset] > 5) {
	                var choose = Math.floor(Math.random()*4);
	                if(choose == 0 || choose == 1) { ran = board[i-1][j];}
	                if(choose == 2) { ran = board[i][j-1];}
                }
            }
            board[i][j] = ran;
        }
    }

	return board;
}

export default function Tiles({onTilesFinish, tileset}) {
    const [squares, setSquares] = useState(getRandomStart(tileset));

    const [currentCombo, setCurrentCombo] =  useState(0);
    const [squaresLeft, setSquaresLeft] = useState(300);
    const [moves, setMoves] = useState(0);

	function clearArea(color, c, r) {
		if(r < 0 || r >= 20) {
			return;
		}
		if(c < 0 || c >= 15) {
        	return;
        }
        if(squares[c][r] != color) {
            return;
        }
        const nextSquares = squares.slice();
        squares[c][r] = (-1)*color;
        setSquares(nextSquares);

        clearArea(color, c, r-1);
        clearArea(color, c, r+1);
        clearArea(color, c-1, r);
        clearArea(color, c+1, r);
	}

	function cleanUpArea() {

		var nextSquares = squares.slice();

		for(var i = 0; i < 15; i++) {
			for(var j = 0; j < 20; j++) {
				while(nextSquares[i][j] < 0) {
					nextSquares[i].splice(j,1);
				}
			}
			while(nextSquares[i].length < 20) {
                nextSquares[i].push(0);
            }
		}

		for(var i = 0; i < 15; i++) {
			while(i <= nextSquares.length - 1 && nextSquares[i][0] == 0) {
				nextSquares.splice(i,1);
			}
			while(nextSquares.length < 15) {
				nextSquares.push([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]);
			}
		}
		setSquares(nextSquares);

		var leftSquares = 0;
		for(var i = 0; i < 15; i++) {
			for(var j = 0; j < 20; j++) {
				if(squares[i][j] > 0) {
					leftSquares += 1;
				}
			}
		}


		return leftSquares;
	}


	function handleClick(column, row) {


		if (squares[column][row] == 0 || squares[column][row] == undefined) {
            return;
        }

		clearArea(squares[column][row], column, row);

		const timeout = setTimeout(() => {
            var left = cleanUpArea();
            setSquaresLeft(left);

            if(left == 0) {
                onTilesFinish(moves+1);
            }

        }, 450);

		setMoves(moves+1);

    }

	const R = 20;
	const C = 15;
	var rows = Array(R);
	var columns = Array(C);
	for(var i = 0; i < R; i++) {
		rows[i] = i;
	}
	for(var i = 0; i < C; i++) {
		columns[i] = i;
	}

	function lineCode(num) {
		var row = R - num - 1;
		return (
			<tr className="board-row">{
				columns.map(column => (
					<Square tileset={tileset} key={C*num+column} value={squares[column][row]}
					onSquareClick={() => handleClick(column,row)} />
				))}
			</tr>
		)
	};


	const { t } = useTranslation();
	function getInstruction() {
	    return t("moveInfoSameTiles");
	}

	const boardCode = (<div className="fullBoard">

			<Row>
				<Row className="instruction">
                    <div className="col2"><p className="instructionText"> {getInstruction()} </p></div>
                </Row>

				<Col sm={9} className="gameBoardCol">
					<Table className="gameBoard"><tbody>
					{rows.map(row => lineCode(row))}
					</tbody></Table>
				</Col>
				<Col sm={3}>
					<Row className="gameStats">
					<div className="col1">{ t("LEFT") }<br/><h1>{ squaresLeft }</h1></div>
					<div className="col1">{ "" }<br/><h1>{ "" }</h1></div>
					<div className="col1">{ t("MOVES") }<br/><h1>{ moves }</h1></div>
					<br/>
					<Button variant="success" className="small-btn" onClick={() =>  window.location.reload()}>RESTART</Button>
					</Row>
				</Col>
			</Row>

	    </div>
	);

    return boardCode;
}

