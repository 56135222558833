import logo from './../../logo.svg';
import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import Instruction from './../Instruction';
import results from './../../static/json/results.json';
import levels_calendar from './../../static/json/levels_calendar.json';
import './../../static/css/minigames.css';

import 'animate.css';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');



function Square({image, value, squareClass, onSquareClick, tileset, parts}) {
	var moreClasses = "";

	if(!squareClass.includes("goodImage")) {
		squareClass += " hiddenImage";
	}



    return (
        <td className={"minigameSquare minigameSquare" + parts + "parts " + squareClass }  onClick={onSquareClick}>
            <img className="shuzzleImage" src={"/images/games/calendar/" + tileset + "/" +  value + ".png"} alt="Tile"/>
        </td>
    );
}


function isValid(board, row, col, num, a, b) {
    const subgridRowStart = Math.floor(row / b) * b;
    const subgridColStart = Math.floor(col / a) * a;

    // Check the row
    for (let i = 0; i < a*b; i++) {
        if (board[row][i] === num) return false;
    }

    // Check the column
    for (let i = 0; i < a*b; i++) {
        if (board[i][col] === num) return false;
    }

    // Check the 2x3 subgrid
    for (let i = 0; i < b; i++) {
        for (let j = 0; j < a; j++) {
            if (board[subgridRowStart + i][subgridColStart + j] === num) return false;
        }
    }

    return true;
}

function fillBoard(board, row, col, a, b) {
    if (row === a*b) return true;
    if (col === a*b) return fillBoard(board, row + 1, 0, a, b);

    // Try numbers 1 to 6 in the current cell
    let numbers = [];
    for(let i = 1; i <= a*b; i++) {
        numbers.push(i);
    }

    numbers.sort(() => Math.random() - 0.5); // Shuffle numbers for randomness

    for (let num of numbers) {
        if (isValid(board, row, col, num, a, b)) {
            board[row][col] = num;
            if (fillBoard(board, row, col + 1, a, b)) {
                return true;
            }
            board[row][col] = 0; // Backtrack
        }
    }

    return false; // Trigger backtracking
}


function shuffle(array) {
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
}

function similar(a, b) {
	for(var i = 0; i < a.length; i++) {
		if(a[i] == b[i]) {
			return true;
		}
	}
	return false;
}

function getRandomStart(parts) {
	var original = [];
	var shuffled = [];
	for (let i = 1; i < parts+1; i++) {
        for (let j = 1; j < parts+1; j++) {
            original.push("a" + (10*i+j).toString());
            shuffled.push("a" + (10*i+j).toString());
            original.push("b" + (10*i+j).toString());
            shuffled.push("b" + (10*i+j).toString());
            original.push("c" + (10*i+j).toString());
            shuffled.push("c" + (10*i+j).toString());
            original.push("d" + (10*i+j).toString());
            shuffled.push("d" + (10*i+j).toString());
        }
    }
	shuffle(shuffled);
	while(similar(original, shuffled)) {
		shuffle(shuffled);
	}

	var boards = {"a": [], "b": [], "c": [], "d": []}, x = 0;
	const R = parts, C = parts;
	for(let t = 0; t < 4; t++) {
		var type = ["a","b","c","d"][t];
		for (let i = 0; i < C; i++) {
	        boards[type][i] = [];
	        for (let j = 0; j < R; j++) {
	            boards[type][i][j] = shuffled[x];
	            x++;
	        }
	    }
	}

    return boards;
}

function getEmptyBoards(parts) {
	const boards = {};
	const R = parts, C = parts;
	for(let t = 0; t < 4; t++) {
        var type = ["a","b","c","d"][t];
        boards[type] = [];
	    for (let i = 0; i < C; i++) {
	        boards[type][i] = [];
	        for (let j = 0; j < R; j++) {
	            boards[type][i][j] = 0;
	        }
	    }
	}
	return boards;
}

function getParts(tileset) {
	if(levels_calendar["hard"].includes(Number(tileset))) {return 3;}
    else { return 2;}
}


export default function Shuzzle({onTilesFinish, tileset}) {

    const parts = getParts(tileset);
    const [fixed, setFixed] = useState(getEmptyBoards(parts));
    const [squares, setSquares] = useState(getRandomStart(parts));

    const [currentCombo, setCurrentCombo] =  useState(0);
    const [longestCombo, setLongestCombo] =  useState(0);
    const [points, setPoints] =  useState(0);
    const [moves, setMoves] = useState(0);
    const [moveMessage, setMoveMessage] = useState("");
    const [message, setMessage] = useState("");
    const [alert, setAlert] = useState("");

    const [columnPrev, setColumnPrev] = useState(-1);
    const [rowPrev, setRowPrev] = useState(-1);
    const [typePrev, setTypePrev] = useState(-1);
    const [clickedSquares, setClickedSquares] = useState(getEmptyBoards(parts));

	var timeStopped = false;


	function clearBoard() {

	    setSquares(getRandomStart(parts));
	    setClickedSquares(getEmptyBoards(parts));
	    setMoves(0);
	    setTypePrev(-1);
	    setRowPrev(-1);
	    setColumnPrev(-1);
	}


	function checkPair(type1, row1, col1, type2, row2, col2) {

		var value1 = squares[type1][row1][col1];
		var value2 = squares[type2][row2][col2];

		squares[type1][row1][col1] = value2;
		squares[type2][row2][col2] = value1;
		setSquares(squares);

		clickedSquares[type1][row1][col1] = 0;
		clickedSquares[type2][row2][col2] = 0;
		setClickedSquares(clickedSquares);

		setTypePrev(-1);
		setColumnPrev(-1);
		setRowPrev(-1);

		return true;
	}

	function checkBoard() {
		var diff = 0;
		var boards = ["a","b","c","d"];
		for(var i = 0; i < 4; i++) {
			var b = boards[i];
			var err = 0;
			var type = squares[b][0][0][0];
			for(var j = 0; j < parts; j++) {
				for(var k = 0; k < parts; k++) {
					if(squares[b][j][k][0] != type) {err++; }
					if(squares[b][j][k].substring(1) != (10*(j+1)+(k+1)).toString() ) {err++;}
				}
			}
			console.log(err);
			if(err == 0) {
				for(var j = 0; j < parts; j++) {
					for(var k = 0; k < parts; k++) {
						fixed[b][j][k] = 1;
					}
				}
				setFixed(fixed);
			} else {
				diff++;
			}


		}
		return diff;
	}


	function handleClick(type, row, column) {


		console.log(type, row, column, squares[type][row][column]);
		if(squares[type][row][column] == undefined ) {
            return;
        }

		if(fixed[type][row][column] == 1) {
			setMoveMessage("This piece is in the right place. Don't mess it up!");
            return;
        }

        if(clickedSquares[type][row][column] == 1 || (type == typePrev && column == columnPrev && row == rowPrev)) {

            setTypePrev(-1);
            setColumnPrev(-1);
            setRowPrev(-1);
            clickedSquares[type][row][column] = 0
            setClickedSquares(clickedSquares);
            setMoveMessage("");
            return;
        }



        if(columnPrev == -1) {


            clickedSquares[type][row][column] = 1;
            setClickedSquares(clickedSquares);
            setColumnPrev(column);
            setRowPrev(row);
            setTypePrev(type);
            setMoveMessage("");

        } else {

			clickedSquares[type][row][column] = 1;
            setClickedSquares(clickedSquares);

            if(checkPair(typePrev, rowPrev, columnPrev, type, row, column)) {
                setMoves(moves+1);

                setMoveMessage("");
                var diff = checkBoard();
                setPoints(diff);
                if(diff == 0) {
                    onTilesFinish(moves+1);
                }
            } 

        }

    }

	const R = parts;
	const C = parts;
	var rows = Array(R);
	var columns = Array(C);
	for(var i = 0; i < R; i++) {
		rows[i] = i;
	}
	for(var i = 0; i < C; i++) {
		columns[i] = i;
	}

	function lineCode(type, num) {
		var row = num;
		return (
			<tr className="board-row">{
				columns.map(column => (
					<Square image={"a"} tileset={tileset} key={C*num+column} value={squares[type][row][column]}
                        squareClass={ clickedSquares[type][row][column] == 1 ? 'chosenImage' :
                                      fixed[type][row][column] == 1 ? 'fullImage goodImage' : '' }
						onSquareClick={() => handleClick(type, row,column)}
						parts = {parts} />

				))}
			</tr>
		)
	};


	const { t } = useTranslation();
	function getInstruction() {
	    return t("moveInfoShuzzle");
	}


	const boardCode = (<div className="fullBoardBig">

			<Row>
                <Row className="instruction">
                    <p className="instructionText"> {getInstruction()} </p>
                </Row>
				<div style={{"height": "30px"}}>
					<div style={{"background-color": "red", "color": "white", "max-width": "360px", "margin": "auto",
					"margin-bottom": "10px", "border-radius": "3px"}}>
					{ moveMessage }
					</div>
				</div>

				<Col sm={9} className="gameBoardCol">
					<Table className="minigameGameBoardDouble shuzzle"><tbody>
					{rows.map(row => lineCode("a", row))}
					</tbody></Table>
					<Table className="minigameGameBoardDouble shuzzle"><tbody>
					{rows.map(row => lineCode("b", row))}
					</tbody></Table>
					<Table className="minigameGameBoardDouble shuzzle"><tbody>
					{rows.map(row => lineCode("c", row))}
					</tbody></Table>
					<Table className="minigameGameBoardDouble shuzzle"><tbody>
					{rows.map(row => lineCode("d", row))}
					</tbody></Table>
				</Col>
				<Col sm={3}>
					<Row className="gameStats">
					<div className="col1">{ }<br/></div>
					<div className="col1">{ "SWAPS" }<br/><h1>{ moves }</h1></div>

					<div className="col1">{  }<br/></div>
					<br/>
					<Button variant="success" className="small-btn" onClick={() =>  clearBoard()}>RESET</Button>
					</Row>
				</Col>
			</Row>


	    </div>
	);

    return boardCode;
}

