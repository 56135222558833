import React from 'react';

import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import '../../static/css/tilesets.css';

import { useNavigate } from "react-router-dom";

import general from '../../static/json/general.json';
import Minigames from "./Minigames.js"
import Moregames from "./Moregames.js"

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');

function MainPage({game, lang}) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	function play() {

		ReactGA.event({
	        category: 'Game',
	        action: 'StartMiniGame'
	    });

	}

	function getNewestTileset() {
		const tilesgames = ["tiles", "tilesbig", "pairs", "infinite", "timed", "unpaired"];
		var listName = tilesgames.includes(game) ? "waiting" : game;

		var tilesets = general[listName], ts;
		const todayDate = new Date();
		var dateDate = new Date();
		var first = "";

		for(var i = 0; i < tilesets.length; i++) {
			ts = tilesets[i];
			dateDate = new Date(ts["date"]);
			if(dateDate <= todayDate) {
				if(first == "") {first = ts["name"]}
				if(localStorage.getItem(game + "-" + ts["name"] + "-best") == null) {
            	    return ts["name"];
            	}
            }
		}
		return first;
	}


	function playNewestGame() {
		var tileset = getNewestTileset(game);

		function play() {
			ReactGA.event({
		        category: 'Game',
		        action: 'StartGameMain'
		    });
		}

		window.location.href = ("/" + lang + "/" + game + "/" + tileset);
	}

	const title = t(game + "Title");
	const shortInstruction = t(game + "Instruction");

    return (
        <div className="mainPart">
        <Container>
          <Row>
            <><h1>{title}</h1>
    	    <p className="intro">{shortInstruction}</p>
    	    <div><Button variant="success" className="default-btn" onClick={() => playNewestGame()}>{t('play')}</Button>
    	    </div>
    	    <a href={"/" + lang + "/" + game + "/tilesets"}>{t('chooseTileset')}</a>
    	    </>

          </Row>

        </Container>
        </div>
      );
}

function MoreInfo({game}) {
	const { t } = useTranslation();
	return (
		<>
			<Container className="moreIconTiles">
        	            <Row>
        	                <img alt="Tile1" src={"/images/games/" + game + "/pic1.png"} />
        	                <img alt="Tile2" src={"/images/games/" + game + "/pic2.png"} />
        	                <img alt="Tile3" src={"/images/games/" + game + "/pic3.png"} />
        	            </Row>
        	        </Container>
			<Container className="moreInfo" >
				<Row>
					<Col sm={9}>
						<h3>{t(game + "Header1")}</h3>
						<p>{t(game + "Text1")}</p>
					</Col>
					<Col sm={3}><img alt={game + "  game example"} src={"/images/games/" + game + "/pic1.png"} /></Col>
				</Row>
				<Row>
					<Col sm={3}><img alt={game + " game"} src={"/images/games/" + game + "/pic2.png"}  /></Col>
					<Col sm={9}>
						<h3>{t(game + "Header2")}</h3>
						<p>{t(game + "Text2")}</p>
					</Col>
				</Row>
				<Row>
					<Col sm={9}>
						<h3>{t(game + "Header3")}</h3>
						<p>{t(game + "Text3")}</p>
					</Col>
					<Col sm={3}><img alt={game + " shuzzle game"} src={"/images/games/" + game + "/pic3.png"} /></Col>
				</Row>
				<Row>
					<h3>{t(game + "Header4")}</h3>
					<p>{t(game + "Text4")}</p>

				</Row>
			</Container>


		</>
	);
}

function Main({lang, game}) {

	const { t } = useTranslation();
	const langBasic = (lang == "en") ? "" : lang;

	React.useEffect(() => {
          window.scrollTo(0, 0);
        }, []);

    const title = t(game + "Title");
    const description = t(game + "Description");

	return (
		<>
			<Helmet>
                <title>{ title }</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={"https://www.unlimited-tiles.com/" + lang + "/" + game}  />

                <meta name="keywords" content="Tiles unlimited, tiles game, relaxing game, tiles the game, tiles" data-react-helmet="true"  />
                <meta property="og:url" content={"https://www.unlimited-tiles.com/" + lang + "/" + game}  />

                <meta property="og:image" content={"https://www.unlimited-tiles.com/images/games/" + game + "/default.png"} />
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={description}/>
            </Helmet>
			<MainPage game={game} lang={lang}/>
			<br/>

			<MoreInfo game={game}/>

			{ game == "swapoku" || game == "neighbours" || game == "segments" || game == "same" || game == "shuzzle" || game == "sparkit" || game == "pixit"
			?   <div className="mainPart">
					<Container className="instructionInfo">
					<h2>{t(game + "HowToPlay")}</h2>
					<ol>
						<li>{t(game + "Instruction1")}</li>
						<img alt={"How to play " + game} src={"/images/instruction/" + game + "/instruction1.png"}/>
						<li>{t(game + "Instruction2")}</li>
						<img alt={"How to play " + game + " example"} src={"/images/instruction/" + game + "/instruction2.png"}/>
						<li>{t(game + "Instruction3")}</li>
						<img alt={"How to play " + game + " game"} src={"/images/instruction/" + game + "/instruction3.png"}/>
						<li>{t(game + "Instruction4")}</li>

					</ol>

					 <center><a href={"/" + lang + "/" + game + "/tilesets"}>Choose level</a></center>
					</Container>
	            </div>
            :   <></>

            }

            <br/>
		</>

	);
};

export default Main;
