import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');


function Tileset({game, tileset}) {

	const navigate = useNavigate();
	const { t } = useTranslation();
	const detectedLanguage = window.location.pathname.split('/')[1];

	var puzzle = (game == "shuzzle" || game == "pixit") ? "Puzzle" : "Level";

	function getUrl({game, tileset}) {
    	return ("/" + "en" + "/" + game + "/" + tileset);
    }

    function playGame({game, tileset}) {
	    ReactGA.event({
	        category: 'Game',
	        action: 'StartGameFromArticle'
	    });
	    window.location.href = getUrl({game, tileset});
    }

    const gameImage = (["infinite", "timed", "unpaired"].includes(game)) ? "tiles" : game;
    const bigClass = (["swapoku", "pixit", "shuzzle", "lines"].includes(game)) ? "articleTilesetBig" : "";

    return (
        (<Col className='tilesetContainer articleTilesetContainer'>
            <Card onClick={() => playGame({game, tileset})} className={"tilesetCard articleTileset " + bigClass}>
                <Card.Img alt={game + " game " + tileset } variant="top" src={"/images/games/" + gameImage + "/" + tileset + ".png"}  />

                <Card.Body>
                    <Card.Title>{puzzle} {tileset}</Card.Title>
                    <Button className="small-btn" variant="primary"
                            onClick={() => playGame({game, tileset})}>{t("play")}</Button>

                </Card.Body>
            </Card>
        </Col>)

    );
}

function Part({name, text, examples}) {
	return (<>
		<h2> {name} </h2>
		<p> {text} </p>
		{
			examples == undefined
			? <></>
			: <Row>
				{
				examples.map(lvl => (
					<Tileset game={lvl["game"]} tileset={lvl["tileset"]} />
				))
				}
			</Row>
		}
	</>);
}

const Article = ({article}) => {

	//article = article.toString();

	var text = require("../static/json/articles/" + article + ".json");


	const tilesetHtml =  (
        <div className="mainPart">
        <Helmet>
            <title>{text["title"]}</title>
            <meta name="description" content={text["title"]} />
            <link rel="canonical" href={"https://www.unlimited-tiles.com/blog/" + article} />
            <meta property="og:url" content={"https://www.unlimited-tiles.com/blog/" + article} />
            <meta property="og:image" content={"https://www.unlimited-tiles.com/images/games/shuzzle/default.png"} />
            <meta property="og:title" content={text["title"]} />
            <meta property="og:description" content="Find hidden words "/>
        </Helmet>

        <Container className="article">
            <h1>{text["title"]}</h1>
            <center><h5>{text["subtitle"]}</h5></center>
			{
				text["article"].map(p => (
					<Part name={p["name"]} text={p["text"]} examples={p["examples"]} key={p["name"]}/>
				))
			}


        </Container>
        </div>
      );

    return tilesetHtml
}

export default Article;

