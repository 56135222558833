import logo from './../../logo.svg';
import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import Instruction from './../Instruction';
import results from './../../static/json/results.json';
import './../../static/css/neighbours.css';

import 'animate.css';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');



function Square({value, squareClass, onSquareClick, tileset}) {
	var tiles = "";

	function getImage() {
		if(value > 0) {
			return <div className="image"><img src={"/images/games/neighbours/" + tileset + "/d" + value + ".png"} alt="Tile"/></div>;
		} else if(value < 0) {
			return <div className="image animate__animated animate__backOutLeft"><img src={"/images/games/neighbours/" + tileset + "/d" + (-1)*value + ".png"} alt="Tile"/></div>;
		} else {
			return "";
		}
	}

	function getBackground() {
		return <div className="image"><img src={"/images/tilesets/background.png"} alt="X"/></div>;
	}

    return (
        <td className={"neighboursSquare " + squareClass}  onClick={onSquareClick}>
            { getImage() }

        </td>
    );
}

function getRandomStart(tileset) {
	const board = [];
	const R = 8, C = 8;
	const values = results["neighbours"]["values"][tileset];
	const shapes = values[0];
	const colors = values[1];

    for (let i = 0; i < C; i++) {
        board[i] = [];
        for (let j = 0; j < R; j++) {
            var ran = (1+Math.floor(Math.random()*shapes))*10 + (1+Math.floor(Math.random()*colors));
            board[i][j] = ran;
        }
    }
	return board;
}

function getEmptyBoard() {
	const board = [];
	const R = 8, C = 8;
    for (let i = 0; i < C; i++) {
        board[i] = [];
        for (let j = 0; j < R; j++) {
            board[i][j] = 0;
        }
    }
	return board;
}

export default function Tiles({onTilesFinish, tileset}) {
    const [squares, setSquares] = useState(getRandomStart(tileset));

    const [currentCombo, setCurrentCombo] =  useState(0);
    const [points, setPoints] =  useState(0);
    const [longestCombo, setLongestCombo] =  useState(0);
    const [moves, setMoves] = useState(0);
    const [moveMessage, setMoveMessage] = useState("");
    const [message, setMessage] = useState("");
    const [alert, setAlert] = useState("");

    const [columnPrev, setColumnPrev] = useState(-1);
    const [rowPrev, setRowPrev] = useState(-1);
    const [clickedSquares, setClickedSquares] = useState(getEmptyBoard());

	var timeStopped = false;


	function cleanUpArea() {

		var nextSquares = squares.slice();
		for(var i = 0; i < C; i++) {
			for(var j = 0; j < R; j++) {
				clickedSquares[i][j] = 0;
				while(nextSquares[i][j] < 0) {
					nextSquares[i].splice(j,1);
				}
			}
			while(nextSquares[i].length < R) {
                nextSquares[i].push(0);
            }
		}
		setClickedSquares(clickedSquares);

		for(var i = 0; i < C; i++) {
			while(i <= nextSquares.length - 1 && nextSquares[i][0] == 0) {
				nextSquares.splice(i,1);
			}
			while(nextSquares.length < C) {
				nextSquares.push([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]);
			}
		}
		setSquares(nextSquares);

		var leftSquares = 0;
		for(var i = 0; i < C; i++) {
			for(var j = 0; j < R; j++) {
				if(squares[i][j] > 0) {
					leftSquares += 1;
				}
			}
		}


		return leftSquares;
	}

	function resetCombo() {
		if(timeStopped) {
			return;
		}
		timeStopped = true;
		for (let i = 0; i < C; i++) {
	        for (let j = 0; j < R; j++) {
	            if(clickedSquares[i][j] == 1) {
	                squares[i][j] = (-1)*Math.abs(squares[i][j]);
	            }
	        }
	    }
	    setColumnPrev(-1);
        setRowPrev(-1);
        var resultPoints = points + currentCombo*currentCombo;
        setPoints(points + currentCombo*currentCombo);
        setCurrentCombo(0);
	    setSquares(squares);

	    const timeout = setTimeout(() => {
	        var left = cleanUpArea();
	        if(left == 0) {
	            onTilesFinish(resultPoints);
	        }
			timeStopped = false;
	    }, 500);
	}

	function clearCombo() {
		if(timeStopped) {
			return;
		}
		timeStopped = true;
		for (let i = 0; i < C; i++) {
	        for (let j = 0; j < R; j++) {
	            if(clickedSquares[i][j] == 1) {
	                clickedSquares[i][j] = 0;
	            }
	        }
	    }
	    setColumnPrev(-1);
        setRowPrev(-1);
        setCurrentCombo(0);
	    setClickedSquares(clickedSquares);
		var left = cleanUpArea();
		timeStopped = false;
	}

	function checkNeighbours(col2, row2, col3, row3) {
		if(col3 < 0 || col3 >= C || row3 < 0 || row3 >= R) {
			return 0;
		}
		if(clickedSquares[col3][row3] != 0) {
			return clickedSquares[col3][row3];
		}

		var color2 = squares[col2][row2] % 10;
		var shape2 = (squares[col2][row2] - color2)/10;
		var color3 = squares[col3][row3] % 10;
		var shape3 = (squares[col3][row3] - color3)/10;
		if((color2 == color3 && shape2 != shape3) || (color2 != color3 && shape2 == shape3)) {
			setMessage("Click on the white square to continue building the chain!");
			return 2;
		} else {
			return 0;
		}
	}

	function checkPair(col1, row1, col2, row2) {

		var color1 = squares[col1][row1] % 10;
		var shape1 = (squares[col1][row1] - color1)/10;
		var color2 = squares[col2][row2] % 10;
		var shape2 = (squares[col2][row2] - color2)/10;

		if(Math.abs(col1-col2) + Math.abs(row1-row2) != 1) {
			setMoveMessage(message);
			return false;
		}

		if(squares[col1][row1] == squares[col2][row2]) {
			setMoveMessage(message);
            return false;
		}

		if(color1 != color2 && shape1 != shape2) {
			setMoveMessage(message);
            return false;
		}

		clickedSquares[col2][row2] = 1;


		for(var i = 0; i < C; i++) {
            for(var j = 0; j < R; j++) {
        		if(clickedSquares[i][j] == 2) {
        		    clickedSquares[i][j] = 0;
        	    }
        	}
        }
		var neighbours = [[0,-1],[0,1],[-1,0],[1,0]];
		setMessage("Click on the navy square to finish the chain!");
		for(var i = 0; i < 4; i++) {
			var n = neighbours[i];
			var col3 = col2+n[0];
			var row3 = row2+n[1];
			var value = checkNeighbours(col2, row2, col3, row3);
			if(value == 2) {
				clickedSquares[col3][row3] = value;
			}
		}

		setClickedSquares(clickedSquares);


        setLongestCombo(Math.max(longestCombo, currentCombo+1));
        setCurrentCombo(currentCombo+1);

		setColumnPrev(col2);
        setRowPrev(row2);

		return true;
	}


	function handleClick(column, row) {
		if(timeStopped || squares[column][row] == 0 || squares[column][row] == undefined ) {
            return;
        }

        if(clickedSquares[column][row] == 1 || (column == columnPrev && row == rowPrev)) {
            resetCombo();
            setMoveMessage("");
            return;
        }


        if(columnPrev == -1) {
            setCurrentCombo(1);
            setLongestCombo(Math.max(longestCombo,1));
            setColumnPrev(column);
            setRowPrev(row);

            clickedSquares[column][row] = 1;
            var neighbours = [[0,-1],[0,1],[-1,0],[1,0]];
			setMessage("Click on the navy square to finish the chain!");
			for(var i = 0; i < 4; i++) {
				var n = neighbours[i];
				var col3 = column+n[0];
				var row3 = row+n[1];
				var value = checkNeighbours(column, row, col3, row3);
                if(value == 2) {
                	clickedSquares[col3][row3] = value;
                }
			}

            setClickedSquares(clickedSquares);
            setMoveMessage("");

        } else {
            if(checkPair(columnPrev, rowPrev, column, row)) {
                setMoves(moves+1);
                setMoveMessage("");
            } else {


            }

        }



    }

	const R = 8;
	const C = 8;
	var rows = Array(R);
	var columns = Array(C);
	for(var i = 0; i < R; i++) {
		rows[i] = i;
	}
	for(var i = 0; i < C; i++) {
		columns[i] = i;
	}

	function lineCode(num) {
		var row = R - num - 1;
		return (
			<tr className="board-row">{
				columns.map(column => (
					<Square tileset={tileset} key={C*num+column} value={squares[column][row]}
                        squareClass={ clickedSquares[column][row] == 1 ? 'redSquare' :
                                      clickedSquares[column][row] == 2 ? 'whiteSquare' : '' }
						onSquareClick={() => handleClick(column,row)} />

				))}
			</tr>
		)
	};


	const { t } = useTranslation();
	function getInstruction() {
	    return t("moveInfoNeighbours");
	}

	const boardCode = (<div className="fullBoard">

			<Row>
                <Row className="instruction">
                    <p className="instructionText"> {getInstruction()} </p>
                </Row>
				<div style={{"height": "30px"}}>
					<div style={{"background-color": "red", "color": "white", "max-width": "360px", "margin": "auto",
					"margin-bottom": "10px", "border-radius": "3px"}}>
					{ moveMessage }
					</div>
				</div>

				<Col sm={9} className="gameBoardCol">
					<Table className="neighboursGameBoard"><tbody>
					{rows.map(row => lineCode(row))}
					</tbody></Table>
				</Col>
				<Col sm={3}>
					<Row className="gameStats">
					<div className="col1">{ "COMBO" }<br/><h1>{ currentCombo }</h1></div>
					<div className="col1">{ "POINTS" }<br/><h1>{ currentCombo*currentCombo }</h1></div>
					<div className="col1">{ "TOTAL" }<br/><h1>{ points }</h1></div>
					<br/>
					<Button variant="success" className="small-btn" onClick={() =>  clearCombo()}>CLEAR</Button>
					</Row>
				</Col>
			</Row>

	    </div>
	);

    return boardCode;
}

