import logo from './../../logo.svg';
import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';

import Instruction from './../Instruction';
import results from './../../static/json/results.json';
import levels_swapoku from './../../static/json/levels_swapoku.json';
import './../../static/css/minigames.css';

import 'animate.css';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');



function Square({value, squareClass, onSquareClick, tileset}) {
	var tiles = "";

	function getImage() {
		if(value > 0) {
			return <div className={"squareNumber " + squareClass}>{ value }</div>;
			//return <div className="image"><img src={"/images/games/swapoku/" + tileset + "/d" + value + ".png"} alt="Tile"/></div>;
		} else if(value < 0) {
			return <div className="image animate__animated animate__backOutLeft"><img src={"/images/games/swapoku/" + tileset + "/d" + (-1)*value + ".png"} alt="Tile"/></div>;
			//return <div className="image animate__animated animate__backOutLeft"><img src={"/images/games/swapoku/" + tileset + "/d" + (-1)*value + ".png"} alt="Tile"/></div>;
		} else {
			return "";
		}
	}

	function getBackground() {
		return <div className="image"><img src={"/images/tilesets/background.png"} alt="X"/></div>;
	}

    return (
        <td className={"minigameSquare minigameSquare6parts"}  onClick={onSquareClick}>
            { getImage() }

        </td>
    );
}


function isValid(board, row, col, num, a, b) {
    const subgridRowStart = Math.floor(row / b) * b;
    const subgridColStart = Math.floor(col / a) * a;

    // Check the row
    for (let i = 0; i < a*b; i++) {
        if (board[row][i] === num) return false;
    }

    // Check the column
    for (let i = 0; i < a*b; i++) {
        if (board[i][col] === num) return false;
    }

    // Check the 2x3 subgrid
    for (let i = 0; i < b; i++) {
        for (let j = 0; j < a; j++) {
            if (board[subgridRowStart + i][subgridColStart + j] === num) return false;
        }
    }

    return true;
}

function fillBoard(board, row, col, a, b) {
    if (row === a*b) return true;
    if (col === a*b) return fillBoard(board, row + 1, 0, a, b);

    // Try numbers 1 to 6 in the current cell
    let numbers = [];
    for(let i = 1; i <= a*b; i++) {
        numbers.push(i);
    }

    numbers.sort(() => Math.random() - 0.5); // Shuffle numbers for randomness

    for (let num of numbers) {
        if (isValid(board, row, col, num, a, b)) {
            board[row][col] = num;
            if (fillBoard(board, row, col + 1, a, b)) {
                return true;
            }
            board[row][col] = 0; // Backtrack
        }
    }

    return false; // Trigger backtracking
}

function generate6x6Sudoku(a,b) {
    let board = Array.from({ length: a*b }, () => Array(a*b).fill(0));
    fillBoard(board, 0, 0, a, b);
    return board;
}


function createBoard(boardAsString) {

	var board = [];
	const R = 6, C = 6;
    for (let i = 0; i < C; i++) {
        board[i] = [];
        for (let j = 0; j < R; j++) {
            board[i][j] = boardAsString[i*R+j];
        }
    }
    return board;
}

function getGoal(tileset) {
	var levels = levels_swapoku["levels"];
	var x = Number(tileset) - 1;
	var level = levels[x]["goal"];
	return createBoard(level);
}


function getRandomStart(tileset) {
	var levels = levels_swapoku["levels"];
	var x = Number(tileset) - 1;
	var level = levels[x]["start"];
	return createBoard(level);
}

function getEmptyBoard() {
	const board = [];
	const R = 6, C = 6;
    for (let i = 0; i < C; i++) {
        board[i] = [];
        for (let j = 0; j < R; j++) {
            board[i][j] = 0;
        }
    }
	return board;
}

export default function Swapoku({onTilesFinish, tileset}) {
    const [goal, setGoal] = useState(getGoal(tileset));
    const [squares, setSquares] = useState(getRandomStart(tileset));
    const total_moves = 30;

    const [currentCombo, setCurrentCombo] =  useState(0);
    const [longestCombo, setLongestCombo] =  useState(0);
    const [points, setPoints] =  useState(0);
    const [moves, setMoves] = useState(0);
    const [moveMessage, setMoveMessage] = useState("");
    const [message, setMessage] = useState("");
    const [alert, setAlert] = useState("");

    const [columnPrev, setColumnPrev] = useState(-1);
    const [rowPrev, setRowPrev] = useState(-1);
    const [clickedSquares, setClickedSquares] = useState(getEmptyBoard());


	const [showPopup, setShowPopup] = useState(false);
    const handleClosePopup = () => setShowPopup(false);
    const handleShowPopup = () => setShowPopup(true);

	var timeStopped = false;


	function clearBoard() {

	    setSquares(getRandomStart(tileset));
	    setClickedSquares(getEmptyBoard());
	    setMoves(0);
	    setRowPrev(-1);
	    setColumnPrev(-1);
	}


	function checkPair(row1, col1, row2, col2) {

		var value1 = squares[row1][col1];
		var value2 = squares[row2][col2];

		squares[row1][col1] = value2;
		squares[row2][col2] = value1;
		setSquares(squares);

		clickedSquares[row1][col1] = 0;
		clickedSquares[row2][col2] = 0;
		setClickedSquares(clickedSquares);

		setColumnPrev(-1);
		setRowPrev(-1);

		return true;
	}

	function checkBoard() {
		var diff = 0;
		for(var i = 0; i < R; i++) {
			for(var j = 0; j < C; j++) {
				if(squares[i][j] != goal[i][j]) {
					diff += 1;
				}
			}
		}
		return diff;
	}


	function handleClick(row, column) {

		if(moves == total_moves) {


			handleShowPopup();
			return;
		}

		if(squares[row][column] == undefined ) {
            return;
        }

		if(squares[row][column] == goal[row][column] ) {
			setMoveMessage(squares[row][column] + " is in the right place. Don't mess it up!");
            return;
        }

        if(clickedSquares[row][column] == 1 || (column == columnPrev && row == rowPrev)) {

            setColumnPrev(-1);
            setRowPrev(-1);
            clickedSquares[row][column] = 0
            setClickedSquares(clickedSquares);
            setMoveMessage("");
            return;
        }



        if(columnPrev == -1) {


            clickedSquares[row][column] = 1;
            setClickedSquares(clickedSquares);
            setColumnPrev(column);
            setRowPrev(row);
            setMoveMessage("");

        } else {
            if(squares[row][column] == squares[rowPrev][columnPrev] ) {
                setRowPrev(-1);
                setColumnPrev(-1);

                clickedSquares[rowPrev][columnPrev] = 0;
                setClickedSquares(clickedSquares);
                setMoveMessage("It's not worth swapping " + squares[row][column] + " with " + squares[row][column] + "! :)");
                return;
            }

			clickedSquares[row][column] = 1;
            setClickedSquares(clickedSquares);

            if(checkPair(rowPrev, columnPrev, row, column)) {
                setMoves(moves+1);
                if(moves+1 == total_moves) {

                    ReactGA.event({
			            category: 'Game',
			            action: 'GameLost',
			            label: "Swapoku"
			        });
                    handleShowPopup();
                }
                setMoveMessage("");
                var diff = checkBoard();
                setPoints(diff);
                if(diff == 0) {
                    onTilesFinish(moves+1);
                }
            } else {


            }

        }

    }

	const R = 6;
	const C = 6;
	var rows = Array(R);
	var columns = Array(C);
	for(var i = 0; i < R; i++) {
		rows[i] = i;
	}
	for(var i = 0; i < C; i++) {
		columns[i] = i;
	}

	function lineCode(num) {
		var row = num;
		return (
			<tr className="board-row">{
				columns.map(column => (
					<Square tileset={tileset} key={C*num+column} value={squares[row][column]}
                        squareClass={ clickedSquares[row][column] == 1 ? 'chosenSquare' :
                                      squares[row][column] == goal[row][column] ? 'goodSquare' : '' }
						onSquareClick={() => handleClick(row,column)} />

				))}
			</tr>
		)
	};


	const { t } = useTranslation();
	function getInstruction() {
	    return t("moveInfoSwapoku");
	}

	function chooseTileset() {
		ReactGA.event({
	        category: 'Game',
	        action: 'GameLostGoToTilesets',
	        label: "Swapoku"
	    });
		window.location.href = "/en/swapoku/tilesets";
	}
	function playAgain() {
		ReactGA.event({
	        category: 'Game',
	        action: 'GameLostPlayAgain',
	        label: "Swapoku"
	    });
		window.location.href = "/en/swapoku/" + tileset;
	}

	const boardCode = (<div className="fullBoard">

			<Row>
                <Row className="instruction">
                    <p className="instructionText"> {getInstruction()} </p>
                </Row>
				<div style={{"height": "30px"}}>
					<div style={{"background-color": "red", "color": "white", "max-width": "360px", "margin": "auto",
					"margin-bottom": "10px", "border-radius": "3px"}}>
					{ moveMessage }
					</div>
				</div>

				<Col sm={9} className="gameBoardCol">
					<Table className="minigameGameBoard sudoku"><tbody>
					{rows.map(row => lineCode(row))}
					</tbody></Table>
				</Col>
				<Col sm={3}>
					<Row className="gameStats">
					<div className="col1">{ "SWAPS" }<br/><h1>{ moves }</h1></div>
					<div className="col1">{ "SWAPS LEFT" }<br/><h1>{ total_moves-moves }</h1></div>
					<div className="col1">{ }<br/><h1>{  }</h1></div>
					<br/>
					<Button variant="success" className="small-btn" onClick={() =>  clearBoard()}>RESET</Button>
					</Row>
				</Col>
			</Row>

			<Modal show={showPopup} onHide={handleClosePopup} >

					<Modal.Body>
					<h3>You lost!</h3>
					<p>You have no more moves, but don't worry! You can play again or choose another level! </p>
                    <Button variant="success" className="default-btn" onClick={playAgain}>{t('playAgain')} </Button><br/>
					<Button  className="small-btn" onClick={chooseTileset}>{t("chooseLevel")}</Button><br/>

					</Modal.Body>
				</Modal>

	    </div>
	);

    return boardCode;
}

