import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Languages from './Languages'

import '../static/css/header.css';
//import logo from '/icon.png'

import general from '../static/json/general.json';
import { useTranslation } from 'react-i18next';


import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');

function Header() {
	const { t } = useTranslation();
	var detectedLanguage = window.location.pathname.split('/')[1];
	if(detectedLanguage == "en" || detectedLanguage.length > 2) {
		detectedLanguage = "";
	}
	var game = "tiles";
	var params = window.location.pathname.split('/');
	if(params.length >= 3) {
		var detectedGame = params[2];
		if(detectedGame.length >= 3) {
			game = detectedGame;
		}
		var games = general["allgames"];
		if(!games.includes(game)) {
			game = "shuzzle";
		}
	}

	function play() {
		ReactGA.event({
	        category: 'Game',
	        action: 'FromHeaderToShuzzle'
	    });
	    window.location.href = "/en/shuzzle"
	}



	return (
		<>
        <Navbar expand="lg" className="bg-body-tertiary tilesNavbar">
            <Container>

                <Navbar.Brand href={"/" + detectedLanguage}>
                    <img className="d-inline-block align-top logo" src="/logo.png" alt="Tiles"/>
                </Navbar.Brand>

                { /*<Languages/>*/ }

				<a href="/badges">
                    <img className="d-inline-block align-right navbar-icon" src="/badges.png" alt="Badges"/>
                </a>
                <a href={"/en/" + game + "/tilesets"}>
                    <img className="d-inline-block align-right navbar-icon" src="/tilesets.png" alt="Tilesets"/>
                </a>
                { /* <a href="https://www.reddit.com/r/UnlimitedTiles/">
                    <img className="d-inline-block align-right navbar-icon" src="/reddit.png" alt="Reddit"/>
                </a> */}

                <a href="/games">
                    <img className="d-inline-block align-right navbar-icon" src="/games.png" alt="Games"/>
                </a>
                {/*<a href="/contact">
                    <img className="d-inline-block align-right navbar-icon" src="/contact.png" alt="Contact"/>
                </a>*/ }
            </Container>
        </Navbar>

        <Navbar  expand="lg" className="bg-body-tertiary tilesNews">

	        <Container onClick={() => play()}>
				<span>Try out our best game - SHUZZLE!</span>
	        </Container>

        </Navbar>


        </>
    );
}

export default Header;