import logo from './../../logo.svg';
import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import Instruction from './../Instruction';

import 'animate.css';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');

const R = 8;
const C = 8;


function Square({value, onSquareClick, squareClass, tileset, toDelete}) {
	var moreClasses = "";

	if(toDelete == 1) { squareClass += " deleting"; }
	if(value.includes("x")) { squareClass += " orangeSquare";}
    return (
        <td className={"minigameSquare minigameSquare8parts " + squareClass}  onClick={onSquareClick}>
            <img className="shuzzleImage" src={"/images/games/sparkit/game/d" + value + ".png"} alt="Tile"/>
        </td>
    );
}



function getRandomValue(tileset) {
	const options = ["01", "12", "23", "30", "02", "13"]
	var ran = options[Math.floor(Math.random()*6)];

	return ran;

}

function getChainsFromTileset(tileset) {

	return Number(tileset.replace("basic", ""));
}

function getRandomStart(tileset) {

	const board = [];
	const options = ["01", "12", "23", "30", "02", "13" ];
	const elements = ["0", "0x", "1", "1x", "2", "2x", "3", "3x"];

	var elementsAmount = 2*Number(tileset.replace("basic", ""));


    for (let i = 0; i < R; i++) {
        board[i] = [];
        for (let j = 0; j < C; j++) {
            var ran = options[Math.floor(Math.random()*options.length)];
            board[i][j] = ran;
        }
    }
	for (let i = 0; i < elementsAmount; i++) {
		var r = Math.floor(Math.random()*R);
		var c = Math.floor(Math.random()*C);
		while(elements.includes(board[r][c])) {
			r = Math.floor(Math.random()*R);
        	c = Math.floor(Math.random()*C);
		}
		var take = i % 8;
		board[r][c] = elements[take];
	}

	return board;
}

function getEmptyStart(squares) {
	const board = [];
    for (let i = 0; i < R; i++) {
        board[i] = [];
        for (let j = 0; j < C; j++) {
            board[i][j] = 0;
        }
    }
	return board;
}


export default function Tiles({onTilesFinish, tileset}) {
    const chainsToFinish = getChainsFromTileset(tileset);
    const [squares, setSquares] = useState(getRandomStart(tileset));
    const [goodSquares, setGoodSquares] = useState(getEmptyStart(squares));
    const [toDelete, setToDelete] = useState(getEmptyStart(squares));
	const [countAgain, setCountAgain] = useState(true);
	const [waiting, setWaiting] = useState(false);

    const [currentCombo, setCurrentCombo] =  useState(0);

    const [left, setLeft] = useState(chainsToFinish);
    const [points, setPoints] = useState(0);

	function clearBoard() {
		setSquares(getRandomStart(tileset));
		setLeft(chainsToFinish);
		setPoints(0);

		setCountAgain(true);
	}

	if(countAgain) {
		setCountAgain(false);
		checkState();
	}

	function getNext(value) {
		const next = {
			"12": "23",
			"23": "30",
			"30": "01",
			"01": "12",
			"02": "13",
			"13": "02",
			"0": "1",
			"1": "2",
			"2": "3",
			"3": "0",
			"0x": "1x",
			"1x": "2x",
			"2x": "3x",
			"3x": "0x"
		}
		return next[value];
	}

	function rotate(column, row) {
		var nextSquares = squares.slice();
		nextSquares[column][row] = getNext(nextSquares[column][row]);

		setSquares(nextSquares);
	}

	function addEnergy(r,c) {
		if(goodSquares[r][c] == 1) {return;}
		goodSquares[r][c] = 1;
		if(squares[r][c].includes("0") && c+1 < C && squares[r][c+1].includes("2")) {
			addEnergy(r, c+1);
		}
		if(squares[r][c].includes("2") && c-1 >= 0 && squares[r][c-1].includes("0")) {
			addEnergy(r, c-1);
		}
		if(squares[r][c].includes("3") && r-1 >= 0 && squares[r-1][c].includes("1")) {
			addEnergy(r-1, c);
		}
		if(squares[r][c].includes("1") && r+1 < R && squares[r+1][c].includes("3")) {
			addEnergy(r+1, c);
		}
	}

	function checkPipes() {
		for (let i = 0; i < R; i++) {
            for (let j = 0; j < C; j++) {
                goodSquares[i][j] = 0;
            }
        }
		for (let i = 0; i < R; i++) {
            for (let j = 0; j < C; j++) {
                if(["0", "1", "2", "3"].includes(squares[i][j])) {
                    addEnergy(i,j);
                }
            }
        }
        setGoodSquares(goodSquares);
	}


	function removePath(r,c) {
		if(toDelete[r][c] == 1) {return;}
		toDelete[r][c] = 1;
		if(squares[r][c].includes("0") && c+1 < C && squares[r][c+1].includes("2")) {
			removePath(r, c+1);
		}
		if(squares[r][c].includes("2") && c-1 >= 0 && squares[r][c-1].includes("0")) {
			removePath(r, c-1);
		}
		if(squares[r][c].includes("3") && r-1 >= 0 && squares[r-1][c].includes("1")) {
			removePath(r-1, c);
		}
		if(squares[r][c].includes("1") && r+1 < R && squares[r+1][c].includes("3")) {
			removePath(r+1, c);
		}
	}


	function checkIfDelete() {
		var tilesToDelete = 0
		for (let i = 0; i < R; i++) {
            for (let j = 0; j < C; j++) {
                if(goodSquares[i][j] == 1 && squares[i][j].includes("x")) {
                    removePath(i,j);
                    setToDelete(toDelete);

                    tilesToDelete += 1;
                }
            }
        }
		return tilesToDelete;
	}

	function removeOldSquares() {

		var newPoints = points;
		for (let i = 0; i < R; i++) {
            for (let j = 0; j < C; j++) {
                if(toDelete[i][j] == 1) {
                    squares[i][j] = "e";
                    toDelete[i][j] = 0;
                    newPoints += 1;
                }
            }
        }
        setPoints(newPoints);
        return newPoints;
    }

	function countBulbs() {

		var bulbs = 0;
		for (let i = 0; i < R; i++) {
            for (let j = 0; j < C; j++) {
                if(squares[i][j].includes("x")) {
                     bulbs += 1;
                }
            }
        }
        return bulbs;
    }

    function addNewSquares() {

        setToDelete(toDelete);

		for (let j = 0; j < C; j++) {
			var x = R - 1;
			for (let i = R - 1; i >= 0; i--) {
				while(x >= 0 && squares[x][j] == "e") { x--; }
				if(x < 0) { squares[i][j] = getRandomValue(tileset); }
				else {
					squares[i][j] = squares[x][j];
					x--;
				}
			}
		}
		setSquares(squares);
	}

	function checkState() {
		checkPipes();
		var tilesToDelete = checkIfDelete();

		if(tilesToDelete > 0) {
			setWaiting(true);

			const timeout = setTimeout(() => {

				var pointsNow = removeOldSquares();
				addNewSquares();
				setCountAgain(true);
				var bulbs = countBulbs();
				setLeft(bulbs);
				if(bulbs == 0) {
					onTilesFinish(pointsNow);
				}
				setWaiting(false);

			}, 1000);

		}
		return tilesToDelete;
	}

	function handleClick(row, column) {

		if(waiting) { return; }

		if (squares[row][column] == "e" || squares[row][column] == undefined) {
            return;
        }

        rotate(row, column);

        var deleted = checkState();

    }


	var rows = Array(R);
	var columns = Array(C);
	for(var i = 0; i < R; i++) {
		rows[i] = i;
	}
	for(var i = 0; i < C; i++) {
		columns[i] = i;
	}

	function lineCode(row) {
		return (
			<tr className="board-row">{
				columns.map(column => (
					<Square tileset={tileset} key={C*row+column} value={squares[row][column]}
					squareClass={ goodSquares[row][column] == 1 ? 'goodImage' : 'greyImage' }
					toDelete={toDelete[row][column]}
					onSquareClick={() => handleClick(row,column)} />
				))}
			</tr>
		)
	};


	const { t } = useTranslation();
	function getInstruction() {
	    return t("moveInfoSparkit");
	}

	const boardCode = (<div className="fullBoard">


			<Row>
				<Row className="instruction">
                    <p className="instructionText"> {getInstruction()} </p>
                </Row>

				<Col sm={9} className="gameBoardCol">
					<Table className="gameBoard pipesGame"><tbody>
					{rows.map(row => lineCode(row))}
					</tbody></Table>
				</Col>
				<Col sm={3}>
					<Row className="gameStats">
					<div className="col1">{ "POINTS" }<br/><h1>{ points }</h1></div>
					<div className="col1">{ "LEFT" }<br/><h1>{ left }</h1></div>
					<div className="col1">{ "" }<br/><h1>{ "" }</h1></div>

					<Button variant="success" className="small-btn" onClick={() =>  clearBoard()}>RESET</Button>
					</Row>
				</Col>
			</Row>

	    </div>
	);

    return boardCode;
}

