import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';


import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';


const Reddit = () => {

	const tilesetHtml =  (
        <div >
        <br/><br/>
        <Container>
            <h2>Join our community on Reddit </h2>


            <p>Come <a href="https://www.reddit.com/r/UnlimitedTiles/">join us on Reddit</a> and be part of the Unlimited Tiles community! It’s the perfect place to connect with other players, share your ideas, ask questions, and suggest improvements.</p>

            <p>We’d love to hear your thoughts and have you help shape the future of the game. Don’t miss out - join the conversation today!</p>


            <p> <b><a href="https://www.reddit.com/r/UnlimitedTiles/">JOIN r/UnlimitedTiles</a></b></p>


        </Container>
        <br/><br/>
        </div>
      );

    return tilesetHtml
}

export default Reddit;

