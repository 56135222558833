import React from 'react';

import '../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import tile1 from '../static/tile1.png'
import tile2 from '../static/tile2.png'
import tile3 from '../static/tile3.png'

import Tiles from './games/Tiles';
import TilesFour from './games/TilesFour';
import Pairs from './games/Pairs';
import Infinite from './games/Infinite';
import Timed from './games/Timed';
import Same from './games/Same';
import Lines from './games/Lines';
import Unpaired from './games/Unpaired';

import Comments from './Comments';

import { Helmet } from 'react-helmet';

import Shares from './Shares';
import Minigames from './games/Minigames';
import Moregames from './games/Moregames';
import Badges from '../library/Badges';

import Confetti from 'react-confetti'

import GoogleAd from './GoogleAd';
import NewAd from './NewAd';

import levels_lines from '../static/json/levels_lines.json';
import general from '../static/json/general.json';
import results from '../static/json/results.json';
import { getStarsAmount, getPointsNeeded } from '../library/stars.js';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');



function getResult(game, tileset, param) {
	return localStorage.getItem(game + "-" + tileset + "-" + param);
}

function setResult(game, tileset, param, result) {
	return localStorage.setItem(game + "-" + tileset + "-" + param, result);
}


function Main({game, playAgain, tileset, lang}) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const fourLayersSets = general["fourlayers"];

	var lastScore = getResult(game, tileset, "last");
	console.log(lastScore);
	if(lastScore == null) {
		window.location.href = "/" + lang + "/" + game + "/" + tileset;
	}


	var lastImage = getResult(game, tileset, "image");
	var dates = getResult(game, tileset, "dates").split(" ");

		var resultTable = (<div><table className="resultsTable">
			<thead><td>{t('date')}</td><td>{t('bestScore')}</td></thead>
			<tbody>
			{ dates.map(date => <tr key={date}><td>{date}</td><td>{getResult(game, tileset, date)}</td></tr> )}
			</tbody></table></div>);

		function textOnFinish(lastScore) {

			if(game != "tiles" && game != "lines" && game != "pairs") {
				return t("sameFinishText");
			}

			if(game == "tiles" && fourLayersSets.includes(tileset)) {
				game = "tilesbig"
			}

			const bestScores = {
				"tiles": 45,
				"tilesbig": 60,
				"pairs": 24
			}
			if(game == "lines") {
				return "Great score! Play again or choose different level!";
			}

			if(bestScores[game] == lastScore) {
				return t("congrats");
			} else {
				return (
					<>
					{t("canYou45").replace("X", bestScores[game])}
					<br/>
					<b>Hint:</b> Remove only 1 layer at a time!
                   </>
				);
			}

		}
		function chooseTileset() {
			if(game == "tilesbig") {
				game = "tiles";
			}
			window.location.href =  "/" + lang + "/" + game + "/tilesets" ;

		}


		function getStars(points) {

			var oldStars = getResult(game, tileset, "stars");
			if(oldStars == null) {
				oldStars = 0;
			} else {
				oldStars = Number(oldStars);
			}
			var starsAmount = getStarsAmount(tileset, game, points);

			var textOnFinish = "";
			if(starsAmount < 3) {
				textOnFinish  = getPointsNeeded(tileset, game);
			}

			return (
					<div>

						<br/>
						{(starsAmount >= 1) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
						: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}
						{(starsAmount >= 2) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
						: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}
						{(starsAmount >= 3) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
						: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}
						<h4>You've earned {starsAmount} {(starsAmount > 1) ? "stars" : "star" }! </h4>

						<br/>

						{ textOnFinish }
					</div>
				);

		}


    var code =  (
            <div className="finish">
                <h1>{t('win')}</h1>

	            {
	                (game == "unpaired")
	                ? <><br/><h5 style={{"margin-bottom": "-2px"}}>Your time is</h5><h1>{lastScore}s</h1> </>
	                : <><br/><h5 style={{"margin-bottom": "-2px"}}>{t('yourScore')}</h5><h1>{lastScore}</h1></>
	            }

	            { getStars(lastScore) }


				{
					game == "tiles"
					? <a href="/blog/how-to-play-tiles">Check how to get more points</a>
					: <></>
				}
				<Badges game={game}/>

	            <div>
	                <Button variant="success" className="default-btn" onClick={chooseTileset}>{
	                    (game == "tiles" || game == "pairs" || game == "infinite" || game == "unpaired")
	                    ?   t("chooseTileset")
	                    :   t("chooseLevel")

	                    }</Button><br/>
	                <Button className="small-btn" onClick={playAgain}>{t('playAgain')} </Button>

	            </div>

            </div>);


	var classNamePart = "mainPart";
	if(game == "tiles" && fourLayersSets.includes(tileset)) {
		classNamePart = "mainPartSpecial";
	}

    return (
        <>
	        <div className={classNamePart} >
		        <Container>
		            <NewAd type="resultTop"/>
		            <Row>
		                { code }
		            </Row>
		            <br/>
		            <NewAd type="resultBottom"/>
		        </Container>
	        </div>


	        <Minigames random={true} game={game}/>

			<div className={classNamePart} >
				<Container >
					<Row>
	                <Shares game={game} tileset={tileset} score={lastScore} image={lastImage}/>

					<h4>Your scores history</h4>
	                { resultTable }
	                </Row>
            	</Container>
            </div>

			<Comments/>
	        <Moregames/>
        </>
      );
}



function Game({game, lang, tileset}) {

	const { t } = useTranslation();
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, []);


	function playAgain() {
		ReactGA.event({
            category: 'Game',
            action: 'StartGameAgain'
        });
        var link = "/" + lang + "/" + game + "/" + tileset;
        window.location.href = link;
    }


	function tilesetToUpper({tileset}) {
		const titles = {
			"tiles": "TILES",
        	"infinite": "INFINITE TILES",
        	"same": "SAME GAME",
        };
        if(tileset in titles) {
        	return titles[tileset];
        } else {
			return tileset.toUpperCase();
		}
	}


	const title = "Unlimited Tiles - " + ((game == "tiles") ? t("title") :  t(game + "Title")) + " - " + tilesetToUpper({tileset}) + " - Result";
	const description = "";


	return (
		<>
			<Helmet>
                <title>{title}</title>

                <link rel="canonical" href={"https://www.unlimited-tiles.com/" + lang + "/" + game + "/" + tileset + "/result"} data-react-helmet="true" />
                <meta property="og:url" content={"https://www.unlimited-tiles.com/" + lang + "/" + game + "/" + tileset + "/result" }  data-react-helmet="true" />

                <meta property="og:image" content={"https://www.unlimited-tiles.com/images/games/" + game + "/default.png"} />
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={title}/>


            </Helmet>

			<Confetti
                  width={window.innerWidth}
                  height={window.innerHeight}
                  recycle={false}
                  numberOfPieces={500}
                  gravity={0.1}
                />
			<Main game={game} playAgain={playAgain} tileset={tileset} lang={lang}/>


		</>

	);
};

export default Game;
