import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../static/css/instruction.css';
import example from '../static/example.png'

import { useTranslation } from 'react-i18next';


function Instruction() {

  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" className="showInstruction" onClick={handleShow}>
        ??
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("howToPlay")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

		<p>{t("instruction1")}</p>
		<img className="imageExample" src={example}/>
		<p className="textExample">{t("instructionExample")}</p>

		<p>{t("instruction2")}</p>
		<p>{t("instruction3")}</p>
		<p>{t("instruction4")}</p>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("backToTheGame")}
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Instruction;

