import logo from './../../logo.svg';
import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import Instruction from './../Instruction';

import 'animate.css';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');



function Square({value, clicked, onSquareClick, tileset}) {
	var tiles = "";

	function getImage() {
		if(value > 0) {
			return <div className="image"><img src={"/images/games/segments/d" + value + ".png"} alt="Tile"/></div>;
		} else if(value < 0) {
			return <div className="image animate__animated animate__backOutLeft"><img src={"/images/games/segments/d" + (-1)*value + ".png"} alt="Tile"/></div>;
		} else {
			return "";
		}
	}

    return (
        <td className={clicked == 1 ? "clickedSquare square7" : " square7"} onClick={onSquareClick}>
            { getImage() }

        </td>
    );
}

function getRandomStart(tileset) {

	const board = [];
	const R = 7, C = 7;
	const colors = {
		"two": 2,
	    "three": 3,
	    "four": 4,
	    "five": 5,
	    "six": 6,
	    "seven": 7,
	    "eight": 8,
	}

    for (let i = 0; i < C; i++) {
        board[i] = [];
        for (let j = 0; j < R; j++) {
            var ran = 1 + Math.floor(Math.random()*colors[tileset]);

            board[i][j] = ran;
        }
    }

	return board;
}
function getEmpty(tileset) {

	const board = [];
	const R = 7, C = 7;
	const colors = {
		"two": 2,  "three": 3,
	    "four": 4,  "five": 5,
	    "six": 6, "seven": 7,  "eight": 8,
	}

    for (let i = 0; i < C; i++) {
        board[i] = [];
        for (let j = 0; j < R; j++) {
            board[i][j] = 0;
        }
    }

	return board;
}
function getRandomSquare(tileset) {

	const colors = {
		"two": 2,
	    "three": 3,
	    "four": 4,
	    "five": 5,
	    "six": 6,
	    "seven": 7,
	    "eight": 8,
	}
	var ran = 1 + Math.floor(Math.random()*colors[tileset]);
	return ran;
}

export default function Segments({onTilesFinish, tileset}) {
    const [squares, setSquares] = useState(getRandomStart(tileset));
    const [clickedSquares, setClickedSquares] = useState(getEmpty(tileset));

    const [currentCombo, setCurrentCombo] =  useState(0);
    const [pairsLeft, setPairsLeft] = useState(49);
    const [moves, setMoves] = useState(0);

	function clearArea(color, c, r) {
		if(r < 0 || r >= R) {
			return;
		}
		if(c < 0 || c >= C) {
        	return;
        }
        if(squares[c][r] != color) {
            return;
        }
        const nextSquares = squares.slice();
        squares[c][r] = (-1)*color;
        setSquares(nextSquares);

        clickedSquares[c][r] = 1;
        setClickedSquares(clickedSquares);

        clearArea(color, c, r-1);
        clearArea(color, c, r+1);
        clearArea(color, c-1, r);
        clearArea(color, c+1, r);
	}

	function cleanUpArea() {

		var nextSquares = squares.slice();

		for(var i = 0; i < C; i++) {
			for(var j = 0; j < R; j++) {
				while(nextSquares[i][j] < 0) {
					nextSquares[i].splice(j,1);
				}
			}
			while(nextSquares[i].length < R) {
				var random = getRandomSquare(tileset);
                nextSquares[i].push(random);
            }
		}

		for(var i = 0; i < C; i++) {
			while(i <= nextSquares.length - 1 && nextSquares[i][0] == 0) {
				nextSquares.splice(i,1);
			}
			while(nextSquares.length < C) {
				nextSquares.push([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]);
			}
		}
		setSquares(nextSquares);

		var leftTiles = 0
		for(var i = 0; i < C; i++) {
			for(var j = 0; j < R; j++) {
				if(clickedSquares[i][j] == 0) {
					leftTiles += 1;
				}

			}
		}


		return leftTiles;
	}

	function checkClick(column, row) {

		if(column >= 1 && squares[column][row] == squares[column-1][row]) {return true;}
		if(column < C-1 && squares[column][row] == squares[column+1][row]) {return true;}
		if(row >= 1 && squares[column][row] == squares[column][row-1]) {return true;}
		if(row < R-1 && squares[column][row] == squares[column][row+1]) {return true;}
		return false;
	}

	function handleClick(column, row) {

		if (squares[column][row] == 0 || squares[column][row] == undefined) {
            return;
        }

        var check = checkClick(column, row);
        if(check) {
            clearArea(squares[column][row], column, row);

			const timeout = setTimeout(() => {
	            var pairs = cleanUpArea();
	            setPairsLeft(pairs);

	            if(pairs == 0) {
	                onTilesFinish(moves+1);
	            }

	        }, 450);

			setMoves(moves+1);
        } else {

            //bad move
        }
    }

    function shuffle() {

		setSquares(getRandomStart(tileset));
        setMoves(moves+1);
    }

	const R = 7, C = 7;
	var rows = Array(R);
	var columns = Array(C);
	for(var i = 0; i < R; i++) {
		rows[i] = i;
	}
	for(var i = 0; i < C; i++) {
		columns[i] = i;
	}

	function lineCode(num) {
		var row = R - num - 1;
		return (
			<tr className="board-row">{
				columns.map(column => (
					<Square tileset={tileset} key={C*num+column} value={squares[column][row]} clicked={clickedSquares[column][row]}
					onSquareClick={() => handleClick(column,row)} />
				))}
			</tr>
		)
	};


	const { t } = useTranslation();
	function getInstruction() {
	    return t("moveInfoSegments");
	}

	const boardCode = (<div className="fullBoard">

			<Row>
				<Row className="instruction">
                    <div className="col2"><p className="instructionText"> {getInstruction()} </p></div>
                </Row>

				<Col sm={9} className="gameBoardCol">
					<Table className="gameBoard"><tbody>
					{rows.map(row => lineCode(row))}
					</tbody></Table>
				</Col>
				<Col sm={3}>
					<Row className="gameStats">
					<div className="col1">{ t("LEFT") }<br/><h1>{ pairsLeft }</h1></div>
					<div className="col1">{ "" }<br/><h1>{ "" }</h1></div>
					<div className="col1">{ t("MOVES") }<br/><h1>{ moves }</h1></div>
					<br/>
					<Button variant="success" className="small-btn" onClick={() => shuffle()}>SHUFFLE</Button>
					</Row>
				</Col>
			</Row>

	    </div>
	);

    return boardCode;
}

