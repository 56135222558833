import logo from './../../logo.svg';
import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import Carousel from 'react-bootstrap/Carousel';


import Instruction from './../Instruction';
import results from './../../static/json/results.json';
import levels_pixit from './../../static/json/levels_pixit.json';
import './../../static/css/minigames.css';

import 'animate.css';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');



function Square({finished, value, squareClass, onSquareClick, tileset, parts}) {
	var moreClasses = "";

	if(!squareClass.includes("goodImage")) {
		squareClass += " waitingImage";
	}

	if(finished) {
		squareClass += " fullImage";
	}


    return (
        <td className={"minigameSquare minigameSquare" + parts + "parts " + squareClass }  onClick={onSquareClick}>
            <img className="shuzzleImage" src={"/images/games/pixit/" + tileset + "/d" + value + ".png"} alt="Tile"/>
        </td>
    );
}



function isValid(board, row, col, num, a, b) {
    const subgridRowStart = Math.floor(row / b) * b;
    const subgridColStart = Math.floor(col / a) * a;

    // Check the row
    for (let i = 0; i < a*b; i++) {
        if (board[row][i] === num) return false;
    }

    // Check the column
    for (let i = 0; i < a*b; i++) {
        if (board[i][col] === num) return false;
    }

    // Check the 2x3 subgrid
    for (let i = 0; i < b; i++) {
        for (let j = 0; j < a; j++) {
            if (board[subgridRowStart + i][subgridColStart + j] === num) return false;
        }
    }

    return true;
}

function fillBoard(board, row, col, a, b) {
    if (row === a*b) return true;
    if (col === a*b) return fillBoard(board, row + 1, 0, a, b);

    // Try numbers 1 to 6 in the current cell
    let numbers = [];
    for(let i = 1; i <= a*b; i++) {
        numbers.push(i);
    }

    numbers.sort(() => Math.random() - 0.5); // Shuffle numbers for randomness

    for (let num of numbers) {
        if (isValid(board, row, col, num, a, b)) {
            board[row][col] = num;
            if (fillBoard(board, row, col + 1, a, b)) {
                return true;
            }
            board[row][col] = 0; // Backtrack
        }
    }

    return false; // Trigger backtracking
}

function getGoal(parts) {
	var board = [];
	const R = parts, C = parts;
    for (let i = 0; i < C; i++) {
        board[i] = [];
        for (let j = 0; j < R; j++) {
            board[i][j] = 10*(i+1)+j+1;
        }
    }
    return board;
}

function shuffle(array) {
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
}

function similar(a, b) {
	for(var i = 0; i < a.length; i++) {
		if(a[i] == b[i]) {
			return true;
		}
	}
	return false;
}

function getRandomStart(parts) {
	var original = [];
	var shuffled = [];
	for (let i = 1; i < parts+1; i++) {
        for (let j = 1; j < parts+1; j++) {
            original.push(10*i+j);
            shuffled.push(10*i+j);
        }
    }
	shuffle(shuffled);
	while(similar(original, shuffled)) {
		shuffle(shuffled);
	}

	var board = [], x = 0;
	const R = parts, C = parts;
    for (let i = 0; i < C; i++) {
        board[i] = [];
        for (let j = 0; j < R; j++) {
            board[i][j] = shuffled[x];
            x++;
        }
    }
    return board;
}

function getEmptyBoard(parts) {
	const board = [];
	const R = parts, C = parts;
    for (let i = 0; i < C; i++) {
        board[i] = [];
        for (let j = 0; j < R; j++) {
            board[i][j] = 0;
        }
    }
	return board;
}

function getParts(tileset) {
	if(levels_pixit["hard"].includes(Number(tileset))) {return 6;}
    else if(levels_pixit["medium"].includes(Number(tileset))) {return 5;}
    else { return 4;}
}

function getAllPuzzles(parts) {
	var all = [];
	const R = parts, C = parts;
    for (let i = 0; i < C; i++) {
        for (let j = 0; j < R; j++) {
            all.push(10*(i+1) + j+1);
        }
    }
    shuffle(all);
	return all;
}

export default function Pixit({onTilesFinish, tileset}) {

    const parts = getParts(tileset);
    const [goal, setGoal] = useState(getGoal(parts));
    const [fixed, setFixed] = useState(getEmptyBoard(parts));
    const [squares, setSquares] = useState(getGoal(parts));

    const [puzzlesLeft, setPuzzlesLeft] = useState(getAllPuzzles(parts));
    const [nextId, setNextId] = useState(0);
    const [currentRotation, setCurrentRotation] = useState(90);

    const [currentCombo, setCurrentCombo] =  useState(0);
    const [longestCombo, setLongestCombo] =  useState(0);
    const [points, setPoints] =  useState(0);
    const [moves, setMoves] = useState(0);
    const [moveMessage, setMoveMessage] = useState("");
    const [messageColor, setMessageColor] = useState("red");
    const [message, setMessage] = useState("");
    const [alert, setAlert] = useState("");

    const [rowPrev, setRowPrev] = useState(-1);
    const [clickedSquares, setClickedSquares] = useState(getEmptyBoard(parts));
    const [finished, setFinished] = useState(false);

	var timeStopped = false;

	function clearBoard() {

	    setPuzzlesLeft(getAllPuzzles(parts));
	    setFixed(getEmptyBoard(parts));
	    setMoves(0);
	}

	function checkBoard() {
		var diff = 0;
		for(var i = 0; i < R; i++) {
			for(var j = 0; j < C; j++) {
				if(squares[i][j] != goal[i][j]) {
					diff += 1;
				}
			}
		}
		return diff;
	}

	function handleClick(row, column) {

		if(squares[row][column] == undefined) {
			setMoveMessage("");
            return;
        }

		if(fixed[row][column] == 1) {
			setMoveMessage("Place already taken, choose another!");
			setMessageColor("red");
			const timeout = setTimeout(() => {
                setMoveMessage(" ");
           	}, 700);
            return;
        }

        if(squares[row][column] == puzzlesLeft[nextId]) {

            setMoveMessage("Correct!");
            setMessageColor("green");
            const timeout = setTimeout(() => {
                setMoveMessage(" ");
           	}, 700);

            fixed[row][column] = 1;
            setFixed(fixed);
            puzzlesLeft.splice(nextId, 1);

            if(puzzlesLeft.length == 0) {
                setFinished(true);
                const timeout = setTimeout(() => {
                    onTilesFinish(moves+1);
                }, 1300);
            }

            if(nextId >= puzzlesLeft.length) {
                var newId = puzzlesLeft.length-1;
                setNextId(newId);
            }

            setPuzzlesLeft(puzzlesLeft);

            var rotations = [0, 90,180,270];
            var randomRotation = rotations[Math.floor(Math.random() * 4)];
            setCurrentRotation(randomRotation);
        } else {
            setMoveMessage("Wrong place, try again!");
			setMessageColor("red");
			fixed[row][column] = 2;
			setFixed(fixed);


			const timeout = setTimeout(() => {
				fixed[row][column] = 0;
				setFixed(fixed);
                setMoveMessage(" ");

           	}, 700);
        }

        setMoves(moves+1);

    }

	function rotate() {
		var rotation = currentRotation + 90;
		if(rotation >= 360) {
			rotation = 0;
		}
		setCurrentRotation(rotation);

        ReactGA.event({
            category: 'Pixit',
            action: 'RotatePixit'
        });
	}


	function showNext() {
		var newId = nextId + 1;
		if(newId >= puzzlesLeft.length) {newId = 0; }
		setNextId(newId);
	}
	function showPrev() {
		var newId = nextId - 1;
		if(newId < 0) {newId = puzzlesLeft.length-1; }
		setNextId(newId);
	}

	function showNextSquare() {

		if(puzzlesLeft.length == 0) {
			return (<></>);
		}
		var value = puzzlesLeft[nextId];

		return (
			<div onClick={() => rotate()} >
			<img className={"rotatedImage" + currentRotation}  src={"/images/games/pixit/" + tileset + "/d" + value + ".png"} alt="Tile"/>

			</div>
		)
	}

	const R = parts;
	const C = parts;
	var rows = Array(R);
	var columns = Array(C);
	for(var i = 0; i < R; i++) {
		rows[i] = i;
	}
	for(var i = 0; i < C; i++) {
		columns[i] = i;
	}

	function lineCode(num) {
		var row = num;
		return (
			<tr className="board-row">{
				columns.map(column => (
					<Square finished={finished} tileset={tileset} key={C*num+column} value={squares[row][column]}
                        squareClass={ clickedSquares[row][column] == 1 ? 'chosenImage' :
                                      fixed[row][column] == 1 ? 'goodImage' :
                                      fixed[row][column] == 2 ? 'badImage' : '' }
						onSquareClick={() => handleClick(row,column)}
						parts = {parts}  />

				))}
			</tr>
		)
	};


	const { t } = useTranslation();
	function getInstruction() {
	    return t("moveInfoPixit");
	}


	const boardCode = (<div className="fullBoard">

			<Row>

                <Row className="instruction">
                    <p className="instructionText"> {getInstruction()} </p>
                </Row>
				<div style={{"height": "30px"}}>
					<div style={{"background-color": messageColor, "color": "white", "max-width": "360px", "margin": "auto",
					"margin-bottom": "10px", "border-radius": "3px"}}>
					{ moveMessage }
					</div>
				</div>

				<Col sm={9} className="gameBoardCol">
					<Table className="minigameGameBoard shuzzle"><tbody>
					{rows.map(row => lineCode(row))}
					</tbody></Table>
				</Col>

				<Col sm={3}>
					<Row className="gameStats">
					<div className="col12"><b>{ "NEXT PIECE" }</b><br/>
						<span style={{"font-size": "14px"}}>Click on a square to rotate it  </span>
						{ showNextSquare() }
					</div>

					<div className="col12">{ "MOVES" }<br/><h1>{ moves }</h1></div>
					<br/><br/><Button variant="success" className="small-btn" onClick={() =>  clearBoard()}>RESET</Button>

					</Row>
				</Col>




			</Row>


	    </div>
	);

    return boardCode;
}

